import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"
import { withRouter } from 'react-router-dom';

import { FormattedMessage } from 'react-intl'

import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

import Channel from './Filter/Channel';
import Language from './Filter/Language';
import MediaType from './Filter/MediaType';
import Decade from './Filter/Decade';

import Publish from './Filter/Publish';

import Broadcast from './Filter/Broadcast';
import Production from './Filter/Production';
import Location from './Filter/Location';

import store from 'index';

import Search from './Search';

import './Sidebar.scss';

const Sidebar = observer(
    class Sidebar extends Component {

        searchStore = store.searchStore;

        state = {
            filters: true
        }

        search = () => {
            this.searchStore.search();
        }

        /*clear = () => {
            this.props.clearFilter();
        }*/

        toggleFilters = () => {
            this.setState({ filters: !this.state.filters });
        }

        renderFilterControls = () => {
            return (
                <Row>
                    {/*
                <Col className={"Button"}>
                    <Button variant="outline-dark" onClick={this.search}>
                        <FormattedMessage id={"sidebar.button.apply"} />
                    </Button>
                </Col>
                */}
                    <Col className={"Button"}>
                        <Button variant="outline-dark" onClick={store.filterStore.clearFilter}>
                            <FormattedMessage id={"sidebar.button.clear"} />
                        </Button>
                    </Col>
                </Row>
            );
        }

        render() {
            const filter = this.state.filters ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />;
            return (
                <Col className={"Sidebar d-none d-lg-block"} >
                    <Row className={"PageHeader"}>
                        <Col className={"SidebarSearch"}>
                            <Search
                                {...this.props}
                                setValue={this.searchStore.setFulltext}
                                getValue={this.searchStore.getFulltext}
                                placeholder={"search.input.results"}
                                onSearch={this.searchStore.onFulltextSearch}
                                field={"fulltext"} />
                        </Col>
                    </Row>

                    {/*<Row className={"Mobile"} >
                    <Col>
                        <div className={"SidebarControl"} onClick={this.toggleFilters}>
                            <h3><FormattedMessage id={"sidebar.section.filter"} /></h3>
                            { filter }
                        </div>
                    </Col>
                </Row>*/}
                    <div className={["Filters", "show-" + this.state.filters].join(' ')}>
                        {this.renderFilterControls()}
                        <hr />
                        <Publish open={true} setFilter={this.props.setFilter} filter={this.props.filter} clear={this.props.clear} search={this.props.search} facetCount={this.props.facetCount} />
                        <MediaType open={true} setFilter={this.props.setFilter} filter={this.props.filter} clear={this.props.clear} search={this.props.search} facetCount={this.props.facetCount} />
                        <Language open={true} setFilter={this.props.setFilter} filter={this.props.filter} clear={this.props.clear} search={this.props.search} facetCount={this.props.facetCount} />
                        <Channel setFilter={this.props.setFilter} filter={this.props.filter} clear={this.props.clear} search={this.props.search} facetCount={this.props.facetCount} />
                        <Decade setFilter={this.props.setFilter} filter={this.props.filter} clear={this.props.clear} search={this.props.search} facetCount={this.props.facetCount} />
                        {/*<Year setFilter={this.props.setFilter} filter={this.props.filter} clear={this.props.clear}/>
                    <Month setFilter={this.props.setFilter} filter={this.props.filter} clear={this.props.clear}/>
                    <Day setFilter={this.props.setFilter} filter={this.props.filter} clear={this.props.clear}/>*/}
                        <Broadcast setFilter={this.props.setFilter} filter={this.props.filter} clear={this.props.clear} search={this.props.search} />
                        <Production setFilter={this.props.setFilter} filter={this.props.filter} clear={this.props.clear} search={this.props.search} />
                        {/*<Location setFilter={this.props.setFilter} filter={this.props.filter} clear={this.props.clear}/>*/}
                        <hr />
                        {this.renderFilterControls()}
                    </div>
                </Col>
            );
        }
    }
)

export default withRouter(Flow.injectFlow(Sidebar));
