import { makeAutoObservable, action, runInAction, reaction } from "mobx"

export default class FilterStore {
    
    filter = {};
    rootStore = null;
    
    constructor(rootStore) {
        makeAutoObservable(this, {
            rootStore: false
        });
        this.rootStore = rootStore;
    }
    
    registerFilter = (field, createSearch = null) => {
        if(!this.filter[field]) {
            this.filter[field] = new Filter(this, field, createSearch);
        }
    }
    
    getAllFilter = () => {
        return this.filter;
    }
    
    getFilter = (field) => {
        if(!this.filter[field]) {
            this.registerFilter(field);
        }
        return this.filter[field];
    }
    
    clearFilter = (search = true) => {
        for(let key in this.filter) {
            this.filter[key].clearData();
        }
        search && this.triggerSearch();
    }
	
	triggerChangePage = () => {
		this.rootStore.paginationStore.changePage(0);
	} 
    
    triggerSearch = () => {
        this.rootStore.searchStore.search();
    }
	
	init = () => {
		const all = this.getAllFilter();
        for(let key in all) {
            all[key].init();
        }
	}
}

export class Filter {
    
    field = false;
    values = [];
    search = [];
    keyword = "";
    clear = false;
    store = null;
	optional = false;	// optional data structure to handle internal operations
    //createSearch = null;
    
    constructor(store, field, createSearch = null) {
        makeAutoObservable(this, {
            store: false,
            createSearch: false,
            clearData: action
        });
        this.store = store;
        this.field = field;
        this.createSearch = createSearch;
    }
    
    updateData = (data) => {
        this.values = data.filter;
        this.search = this.createSearch(data.filter);
		this.store.triggerChangePage();
        this.store.triggerSearch();
    }
    
    setKeyword = (value) => {
        this.keyword = value;
    }
    
    getKeyword = () => {
        return this.keyword;
    }
	
    getValues = () => {
        return this.values;
    }
    
    getSearch = () => {
        return (this.createSearch && this.createSearch(this.values)) || [];
		//return this.search;
    }
	
	setOptional = (optional = false) => {
		this.optional = optional;
	}
    
	getOptional = () => {
		return this.optional;
	}
    
    isClear = () => {
        return this.clear;
    }
    
    clearData = () => {
        this.clear = true;
        this.values = [];
        this.search = [];
        this.keyword = "";
    }
    
    cleared = () => {
        this.clear = false;
    }
    
    init = () => {
        const params = new URL(document.location).searchParams.getAll(this.field);
        if(params.length) {
            this.values = params;
            if(this.createSearch) {
                this.search = this.createSearch(params);
            }
        } else {
			if(this.createSearch) {
                this.search = this.createSearch([]);
            }
		}
    }
}
