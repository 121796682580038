export const loginStandard = (props) => {
    window.flow.login("pjdemo_dummy_1", "gDw5%_wE").then(res => {
        if (props.setUser) {
            props.setUser(res.user);
        }
        if (props.callback) {
            props.callback(res);
        }
    });
}

export const login_old = (props) => {
    window.flow.login(props.user, props.pwd).then(res => {
        window.flow.logout();
        props.onLogin(res.user);
        //loginStandard({ ...props, callback: props.history.goBack });
    }).catch(res => {
        //loginStandard(props);
    });
}

export const login = (props) => {
    //window.flow.nlwLdapLogin('testldap','SL3cxv5_l').then(pres=>{
    window.flow.nlwLdapLogin(props.user, props.pwd).then(res => {
        if (res.getParameters().get('LOGIN') === "SUCCESSFUL") {
            props.onLoginError(false);
            props.onLogin({ id: res.getParameters().get('username'), email: res.getParameters().get('mail'), first_name: res.getParameters().get('firstname'), last_name: res.getParameters().get('lastname'), date: res.getParameters().get('ldapcreated') });
            //props.onLogin(res.user);
        } else {
            props.onLoginError(true);
        }
    }).catch(err => {
        props.onLoginError(true);
    });
}

export const sessionLogin = (props) => {
    window.flow.nlwSessionLdapLogin().then(res => {
        if (res.getParameters().get('LOGIN') === "SUCCESSFUL") {
            props.onLogin({ id: res.getParameters().get('username'), email: res.getParameters().get('mail'), first_name: res.getParameters().get('firstname'), last_name: res.getParameters().get('lastname'), date: res.getParameters().get('ldapcreated') }, true);
        }
    }).catch(err => {
        props.onLogin(null, true);
        /*window.setTimeout(() => {	
            props.onLogin({ id: 'testldap', email: "", first_name: "ldap", last_name: "user" }, true);
        }, 2000);*/
    });
}

export const logout = (props) => {
    window.flow.logout().then(res => {
        props.onLogout();
        //loginStandard({ ...props, callback: props.history.goBack });
    }).catch(res => {
        //loginStandard(props);
    });
}
