// Search results view mode
export const VIEW_MODE_GRID = "grid";
export const VIEW_MODE_LIST = "list";

// Metadata type
export const METADATA_TYPE_CLIP = "clip";

// Search results sorting
export const SORTING_DIRECTION_ASC = "ASC";
export const SORTING_DIRECTION_DESC = "DESC";

export const SORTING_BY_RELEVANCE = "SORTING_BY_RELEVANCE";
export const SORTING_BY_DATE = "SORTING_BY_DATE";

export const SIZE_PHONE = 576;
export const SIZE_TABLET = 992;
export const SIZE_DISPLAY = 1200;

export const CONTENT_TYPE_SPLASH = "splash_page";
export const CONTENT_TYPE_OVERLAY = "player_overlay";
export const CONTENT_TYPE_WARNING = "content_warning";

// content types
export const CONTENT_TYPES = [CONTENT_TYPE_SPLASH, CONTENT_TYPE_OVERLAY, CONTENT_TYPE_WARNING];

export const PROJECT_IDS = {
    "PROD": 69, // camddwr
    "EXT": 69, // wyre
    "TEST": 121, // aeron
    "DEV": 152, // nlw.demo
}

export const _SESSION_TIMEOUT_ = 1800000; // milliseconds = 30 min.

export const _LANG_EN_ = "en";
export const _LANG_CY_ = "cy";

export const _DOMAIN_EN_ = "library.wales";
export const _DOMAIN_CY_ = "llyfrgell.cymru";

export const _DOMAIN_DEV_ = "clipdev";
export const _DOMAIN_TEST_ = "cliptest";
export const _DOMAIN_LIVE_ = "clip";

export const _LINK_HELP_EN_ = "https://www.library.wales/clip-cymru/clip-cymru-help";
export const _LINK_HELP_CY_ = "https://www.llyfrgell.cymru/clip-cymru/cymorth-clip-cymru";

export const YOUTUBE_API_KEY = "";
export const YOUTUBE_CHANNEL_ID = "";
export const YOUTUBE_BASE_URL = "https://youtube.googleapis.com/youtube/v3";
export const YOUTUBE_SEARCH_URL = "/search";
export const YOUTUBE_EMBEDD_URL = "https://www.youtube.com/embed/";
export const YOUTUBE_WATCH_URL = "https://www.youtube.com/watch?v=";
