import { _SESSION_TIMEOUT_ } from "Config/constants";
import { logout } from "Util/Login";

export const setSessionTimeout = () => {
	window.sessionTimeout = Date.now();
}

export const startSessionCheck = (props) => {
	setSessionTimeout();
	window.sessionCheck = window.setInterval(() => {
		if(window.sessionTimeout && (Date.now() - window.sessionTimeout) > _SESSION_TIMEOUT_) {
			stopSessionCheck();
			logout(props);
		}
	}, 60000);
}

export const stopSessionCheck = () => {
	window.clearInterval(window.sessionCheck);
}
