import { format } from 'date-fns';

export const allEmpty = (date) => {
    return !date.day && !date.month && !date.year;
}

export const formatDate = (date, dateFormat = 'yyyy-MM-dd HH:mm:ss') => {
    try {
        return format(date, dateFormat);
    } catch(ex) {
        return "";
    }
}

export const createFromDate = (theDate, dateFormat) => {
    const date = theDate;
    if(!date.year || allEmpty(date)) {
        return "";
    }
    
    if(!date.month) {
        return formatDate(new Date(date.year, 0, 1, 0, 0 ,0), dateFormat);
    }
    
    if(!date.day) {
        return formatDate(new Date(date.year, date.month - 1, 1, 0, 0, 0), dateFormat);
    }
    
    return formatDate(new Date(date.year, date.month - 1, date.day, 0, 0, 0), dateFormat);
}

export const createToDate = (theDate, dateFormat) => {
    const date = theDate;
    if(!date.year || allEmpty(date) ) {
        //return formatDate(new Date(), dateFormat);
        return "";
    }
    
    if(!date.month) {
        return formatDate(new Date(date.year, 12, 0, 23, 59, 59), dateFormat);
    }
    
    if(!date.day) {
        return formatDate(new Date(date.year, date.month, 0, 23, 59, 59), dateFormat);
    }
    
    return formatDate(new Date(date.year, date.month - 1, date.day, 23, 59, 59), dateFormat);
}

export const encodeDate = (date) => {
    const theDate = [];
    // set year
    theDate.push((date.year && formatDate(new Date(date.year, 1, 1), 'yyyy')) || "0000");
    // set month
    theDate.push((date.month && Number.isInteger(parseInt(date.month)) && formatDate(new Date(new Date().getFullYear(), parseInt(date.month) - 1, 1), 'MM')) || "00");
    // set day
    theDate.push((date.day && formatDate(new Date(new Date().getFullYear(), 0, date.day), 'dd')) || "00");
    
    return theDate.join('.');
}

export const decodeDate = (date) => {
    const dateArr = date.split('.');
    const theDate = { day: "", month: "", year: "" };
    
    for(let i = 0; i < dateArr.length; i++) {
        if(i === 0) { theDate.year = parseInt(dateArr[i]) || "" } // set year
        else if(i === 1) { theDate.month = parseInt(dateArr[i]) || "" } // set month
        else if(i === 2) { theDate.day = parseInt(dateArr[i]) || "" } // set day
    }
    
    return theDate;
}

export const splitDate = (str) => {
	const date = {};
	const dates = str.split('-');
            
	if(dates.length === 2) {
		date.from = decodeDate(dates[0]);
		date.to = decodeDate(dates[1]);
	}
	
	return date;
}

export const ldapDate = (str) => {
	if(str && str.length > 8) {
		const year = str.slice(0,4);
		const month = str.slice(4,6);
		const day = str.slice(6,8);
		return day+"/"+month+"/"+year;
	}
	return "";
}