import { FormattedMessage } from 'react-intl';
import  { Form, Button } from 'react-bootstrap';

import Modal from 'react-bootstrap/Modal';

import store from 'index';

const PlaylistClipModal = (props) => {
    let index = -1;
    const options = [];
	let playlists = store.accountStore.getPlaylists();
	const used = store.accountStore.isClipUsed(props.id);
	
	const hasList = (list) => {
		for(let i = 0; i < used.length; i++) {
			if(list.getId() === used[i].getId()) {
				return true;
			}
		}
		return false;
	}

    const onSelect = (evt) => {
        index = parseInt(evt.target.value);
    }
    
    const onAdd = () => {
        const playlist = playlists[index];
		if(!playlist) {
			return false;
		}
        playlist.add(props.id);
        return true;
    }
    
    const renderOptions = () => {
		let out = [];
		out.push(<p key={Math.random()}><FormattedMessage id="modal.playlist.clip.add.text" /></p>);
		if(options.length) {
			out.push(
				<p key={Math.random()}>
                    <Form.Select aria-label="Default select example" onChange={onSelect}>
                        { options }
                    </Form.Select>
                </p>
			);
		}
		
		out.push(
			<p key={Math.random()}>
				<Button variant="dark" onClick={() => props.showPlaylistModal(true)}>
					<FormattedMessage id="page.playlist.add" />
				</Button>
			</p>
		);
		
		return (
            <>{out}</>
        );
    }
    
    const renderIncludes = () => {
        if(!used) return "";
		
		const names = used.map(list => {
			return list.getName();
		});
        
        return (
            <>
                <p><FormattedMessage id="modal.playlist.clip.included" />:</p>
                <p>{ names.join(', ') }</p>
            </>
        );
    }
	
	if(used) {
		playlists = playlists.filter(list => {
			return !hasList(list);
		});		
	}
    
	if(playlists) {
		let playlist; 
        for(let i = 0; i < playlists.length; i++) {
            if(index === -1) { index = i; }
            playlist = playlists[i];
            options.push(
                <option key={i} value={i}>{playlist.getName()}</option>
            );
        }
	}
    
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2><FormattedMessage id="modal.playlist.clip.add.title" /></h2>
        </Modal.Title>
      </Modal.Header>
		<Modal.Body>
            { renderIncludes() }
            { renderOptions() }
		</Modal.Body>
      <Modal.Footer>
        <Button variant={"outline-dark"} onClick={props.onHide}><FormattedMessage id="common.close"/></Button>
        <Button
            disabled={options.length ? false : true}
            variant={"dark"} 
            onClick={(evt) => {
                if(onAdd()) {
                    props.onHide(evt);
                }
            }}><FormattedMessage id="common.save"/></Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PlaylistClipModal;
