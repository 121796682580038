import  { Row, Col, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl'
import { Component } from 'react';
import { observer } from "mobx-react";
import { injectIntl } from 'react-intl';

import CheckboxFilter from './CheckboxFilter';

import './Filter.scss';

const Channel = observer(
    class Channel extends Component {
        
        data = [
            { value:'BBC 2W' },
            { value:'BBC Choice' },
            { value:'BBC One' },
            { value:'BBC One Wales' },
            { value:'BBC Two' }, 
            { value:'BBC Two Wales' }, 
            { value:'BBC Three' },
            { value:'BBC Four' }, 
            { value:'BBC Parliament' },
            { value:'BBC Radio 1' },
            { value:'BBC Radio 2' },
            { value:'BBC Radio 3' },
            { value:'BBC Radio 4' },
            { value:'BBC Radio 5 Live' },
            { value:'BBC Radio 5 Live Sports Extra' },
            { value:'BBC Radio 6 Music' },
            { value:'BBC Radio 7' },
            { value:'BBC Radio Cymru' },
            { value:'BBC Radio Wales' },
            { value:'Channel Four' },
            { value:'Channel Five' },
            { value:'Channel 5' }, 
            { value:'Five' },
            { value:'HTV Wales' },
            { value:'HTV West' },
            { value:'ITV Cymru Wales' },
            { value:'ITV West' },
            { value:'ITV1' }, 
            { value:'ITV1 Wales' },
            { value:'ITV2' },
            { value:'Radio Ceredigion' },
            { value:'S4C' }, 
            { value:'S4C2' }, 
            { value:'S4C Digidol' }
        ];
      
        state = {
            field: 'channelname'
        };
        
        constructor(props) {
            super(props);
        }
        
        createSearch = (filter) => {
            const search = [];
            
            if(filter.length) {
                search.push({field: "", type: "BEGIN_OR", value: ""});
                
                filter.forEach(value => {
                    search.push({field: this.state.field, type:"=", value: value});
                });
                
                search.push({field: "", type: "END_OR", value: ""});
            }
            
            return search;
        }

        render() {
            return (
                <CheckboxFilter 
                    {...this.props } 
                    data={this.data} 
                    field={this.state.field}
                    title={this.props.intl.formatMessage({id: "filter.channels"})}
                    filterable={true}
                    placeholder={this.props.intl.formatMessage({id: "filter.channels.input"})}
                    createSearch={this.createSearch}
                    facetCount={(this.props.facetCount && this.props.facetCount[this.state.field]) || false}/>
            );
        }
    }
)

export default injectIntl(Channel);

