import { _LANG_EN_, _LANG_CY_, _DOMAIN_DEV_, _DOMAIN_TEST_, _DOMAIN_LIVE_ } from "Config/constants";

const _BRANDED_FRAME_DOMAIN_ = "https://brandedframe.library.wales/";
// const _BRANDED_HEADER_ = "header.php?title=CLIP%20Cymru&changeLang=&url=&";
const _BRANDED_HEADER_ = "header.php?title=CLIP%20Cymru&changeLang=&url=&";

const _BRANDED_FOOTER_ = "footer.php?";
const _BRANDED_SITE_EN_TEST_ = "site=cliptestllgc";
const _BRANDED_SITE_CY_TEST_ = "site=clipprawfllgc";
const _BRANDED_SITE_EN_DEV_ = "site=clipdevEn";
const _BRANDED_SITE_CY_DEV_ = "site=clipdevCy";
const _BRANDED_SITE_EN_LIVE_ = "site=cliplibrary";
const _BRANDED_SITE_CY_LIVE_ = "site=clipllyfrgell";

/*

Email Sara 07.07.2022

The codes should be:  
clipllyfrgell = clip.llyfrgell.cymru
cliplibrary = clip.library.wales
clipprawfllgc = cliptest.llyfrgell.cymru
cliptestllgc = cliptest.library.wales

----------------------------------------

Email Dylan 12.05.2022

The dev sites would be
site=clipdevEn [English]
site=clipdevCy [Welsh]

Test site
site=cliptestllgc [English]
site=clipprawfllgc [Welsh]

Live site
site=cliplibrary [English]
site=clipllyfrgell [Welsh]

*/

let headerHeadNodes, headerBodyNodes, footerBodyNodes;

// workaround to remove all invalid nodesfrom response for append/prepend
// e.g. comments, textNodes, ...
/*
let text = httpRequest.response;
text = text.replace(/<!--.*?-->/g, "");
text = text.replace(/(?:\r\n|\r|\n)/g, "");
text = text.replace(/\t/g, "");
text = text.replace(/\>\s*\</g, "><");
*/

document.addEventListener("DOMContentLoaded", function (event) {
    try {
        loadHeaderFragment();
    } catch (ex) {
        console.log(ex);
    }

    try {
        loadFooterFragment();
    } catch (ex) {
        console.log(ex);
    }
});

document.addEventListener('BodyLoaded', function (evt) {
    if (headerBodyNodes && footerBodyNodes) {
        const bodyNodes = headerBodyNodes.concat(footerBodyNodes);

        appendToDOM(bodyNodes, document.body, function () {
            /*let container = document.getElementsByClassName('llgc_main_text')[0];
            let root = document.createElement('div');
            root.id = 'root';
            container.append(root);*/
            document.dispatchEvent(new Event('DOMFinished'));
        });

    }
});

const loadScript = (script) => {
    let s = document.createElement('script');
    let attrs = [...script.attributes];

    attrs.forEach(attr => {
        let name = attr.nodeName;
        let value = attr.nodeValue;
        s.setAttribute(name, value);
    });

    if (script.innerText) {
        let text = script.innerText;
        s.innerText = text;
    }

    return s;
}

// filter node list to only get valid nodes for append/prepend
// doc: html document from DOMParser.parseFromString
// valid: nodeType === 1
const getHeadNodes = (doc) => {
    return [...doc.head.childNodes].filter(node => { return node.nodeType === 1; });
}

// filter node list to only get valid nodes for append/prepend
// doc: html document from DOMParser.parseFromString
// valid: nodeType === 1
const getBodyNodes = (doc) => {
    return [...doc.body.childNodes].filter(node => { return node.nodeType === 1; });
}

const prependToDOM = (nodeList, target, callback) => {
    let elm;
    for (let i = nodeList.length - 1; i >= 0; i--) {
        elm = nodeList[i];
        if (elm && elm.nodeType === 1) {
            if (elm.nodeName === "SCRIPT") {
                elm = loadScript(elm);
            }
            target.prepend(elm);
        }
    }
    callback && callback();
}

const appendToDOM = (nodeList, target, callback) => {
    let elm;
    nodeList.forEach(node => {
        elm = node;
        if (node && node.nodeType === 1) {
            if (elm && elm.nodeType === 1) {
                if (elm.nodeName === "SCRIPT") {
                    elm = loadScript(elm);
                }
                target.append(elm);
            }
        }
    });
    callback && callback();
}

const loadHeaderFragment = () => {
    let httpRequest = new XMLHttpRequest();
    httpRequest.onreadystatechange = function () {
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(httpRequest.response, "text/html");

            headerHeadNodes = getHeadNodes(doc);
            prependToDOM(headerHeadNodes, document.head);
            //appendToDOM(headerHeadNodes, document.head);

            headerBodyNodes = getBodyNodes(doc);
            document.dispatchEvent(new Event('BodyLoaded'));
        }
    };
    httpRequest.open('GET', _BRANDED_FRAME_DOMAIN_ + _BRANDED_HEADER_ + getSite(), true);
    httpRequest.send();
}

const loadFooterFragment = () => {
    let httpRequest = new XMLHttpRequest();
    httpRequest.onreadystatechange = function () {
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            let parser = new DOMParser();
            let doc = parser.parseFromString(httpRequest.response, "text/html");

            footerBodyNodes = getBodyNodes(doc);
            document.dispatchEvent(new Event('BodyLoaded'));
        }
    };
    httpRequest.open('GET', _BRANDED_FRAME_DOMAIN_ + _BRANDED_FOOTER_ + getSite(), true);
    httpRequest.send();
}

const getSite = () => {
    //ACCESSIBILITY BEGIN
    let htmlEl = document.querySelector("html");
    htmlEl.setAttribute("lang", window._LANG_);
    //ACCESSIBILITY

    switch (window._LANG_) {
        case _LANG_EN_:
            /*if(process.env.REACT_APP_SERVER === "PROD") {
                return _BRANDED_SITE_EN_TEST_;
            }
            if(process.env.REACT_APP_SERVER === "EXT") {
                return _BRANDED_SITE_EN_LIVE_;
            }
            if(process.env.REACT_APP_SERVER === "TEST") {
                return _BRANDED_SITE_EN_DEV_;
            }*/
            if (window.location.origin.indexOf(_DOMAIN_DEV_ + '.') !== -1) {
                return _BRANDED_SITE_EN_DEV_;
            }
            if (window.location.origin.indexOf(_DOMAIN_TEST_ + '.') !== -1) {
                return _BRANDED_SITE_EN_TEST_;
            }
            if (window.location.origin.indexOf(_DOMAIN_LIVE_ + '.') !== -1) {
                return _BRANDED_SITE_EN_LIVE_;
            }
            return _BRANDED_SITE_EN_DEV_;
        case _LANG_CY_:
            /*if(process.env.REACT_APP_SERVER === "PROD") {
                return _BRANDED_SITE_CY_TEST_;
            }
            if(process.env.REACT_APP_SERVER === "EXT") {
                return _BRANDED_SITE_CY_LIVE_;
            }
            if(process.env.REACT_APP_SERVER === "TEST") {
                return _BRANDED_SITE_CY_DEV_;
            }*/
            if (window.location.origin.indexOf(_DOMAIN_DEV_ + '.') !== -1) {
                return _BRANDED_SITE_CY_DEV_;
            }
            if (window.location.origin.indexOf(_DOMAIN_TEST_ + '.') !== -1) {
                return _BRANDED_SITE_CY_TEST_;
            }
            if (window.location.origin.indexOf(_DOMAIN_LIVE_ + '.') !== -1) {
                return _BRANDED_SITE_CY_LIVE_;
            }
            return _BRANDED_SITE_CY_DEV_;
        default:
            return "";
    }
}

/*************** NAVIGATION *******************/

const _LINK_SELECTOR_ = 'injected';

const clearNav = () => {
    const elms = document.getElementsByClassName(_LINK_SELECTOR_);
    const length = elms.length;
    for (let i = 0; i < length; i++) {
        elms[0].remove();
    }
}

const createNavLink = (text, href, callback) => {
    const a = document.createElement('a');

    a.classList.add('llgc_navbar_link');
    a.classList.add('text-start');
    a.classList.add('text-nowrap');

    a.innerText = text;

    if (callback) {
        a.onClick = callback;
    } else {
        a.href = href;
    }

    return a;
}

const createNavElement = (text, href, callback) => {
    const a = createNavLink(text, href, callback);
    const li = document.createElement('li');

    li.classList.add('nav-item');
    li.classList.add('llgc_hide_nav');
    li.classList.add(_LINK_SELECTOR_);

    li.append(a);

    return li;
}

export const extendNav = (elms = []) => {
    clearNav();

    let nav, link;
    nav = document.querySelectorAll('#navbarText ul li');

    elms.forEach(elm => {
        link = createNavElement(elm.text, elm.href, elm.callback);
        nav[nav.length - 1].after(link);
    });
}



