import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

import Card from 'react-bootstrap/Card';

import * as Output from 'Util/Output';

import './Clip.scss';

import store from 'index';

class ClipHCard extends Component {
    state = {
        data: false
    }

    searchStore = store.searchStore;

    constructor(props) {
        super(props);
        this.state.data = props.data;
        //this.state.metadata = props.metadata || Metadata.getMetadataObjects(METADATA_TYPE_CLIP);
    }

    onDetail = (id) => {
        this.props.history.push({ pathname: '/detail/' + id, search: this.searchStore.createUrl(), state: { id: id, ids: [...this.props.ids] } });
    }

    onKeyPress = (evt, id) => {
        if (evt.charCode === 32) {
            this.onDetail(id);
        }
    }

    /*
    getThumb = (clip) => {
        if(clip.mainthumbnail && clip.mainthumbnail.getUrl()) {
            return <img alt={""} src={this.state.data.mainthumbnail.getUrl() + '&width=750&height=420'} />;
        }
        
        return (
            <div className={"Icon"}>
                <FontAwesomeIcon icon={faFile} size={this.props.size || "10x"} />
            </div>
        );
    }*/

    getThumb = (clip) => {
        if (clip.mainthumbnail && clip.mainthumbnail.getUrl()) {
            let url = clip.mainthumbnail.getUrl() + '&width=750&height=420';

            if (/*process.env.REACT_APP_SERVER === "PROD" || */
                process.env.REACT_APP_SERVER === "EXT"/* ||
                 process.env.REACT_APP_SERVER === "TEST"*/) {
                try {
                    if (url.indexOf('http') === -1) {
                        url = window.location.protocol + url;
                    }
                    url = new URL(url);
                    url = window.location.origin + url.pathname + url.search;
                } catch (ex) {

                }
            }

            //ACCESSIBILITY
            return <img alt={this.state.data.title || ""} src={url} />;
        }

        return (
            <div className={"Icon"}>
                <FontAwesomeIcon icon={faFile} size={this.props.size || "10x"} />
            </div>
        );
    }

    onKeyUpCard = (cb) => (e) => {
        if (e.key === 'Enter') cb();
    }


    getCardText = (meta) => {

        const output = [];
        const lines = [];
        lines[lines.length] = ["title", "alternativetitle"].filter(item => {
            return meta.fields[item];
        });
        lines[lines.length] = ["seriestitle", "seriesnumber"].filter(item => {
            return meta.fields[item];
        });
        lines[lines.length] = ["episodetitle", "episodenumber"].filter(item => {
            return meta.fields[item];
        });
        lines[lines.length] = ["broadcast_at", "production_date", "duration"].filter(item => {
            return Output.isValid(item, meta);
        });

        lines.forEach((line, index) => {
            if (line.length) {
                let data = line.map(item => {
                    return Output.getOutput(item, meta);
                });
                if (!index) {
                    // output.push(<h3 key={index}>{data.join(' | ')}</h3>);
                    //ACCESSIBILITY
                    output.push(<h2 key={index} style={{ color: 'white' }}>{data.join(' | ')}</h2>);
                } else {
                    output.push(<p key={index}>{data.join(' | ')}</p>);
                }
            }
        });

        return output;
    }

    render() {
        if (!this.state.data)
            return "";

        const clip = this.state.data;
        const meta = clip.getMetadata();
        const thumb = this.getThumb(clip);
        const text = this.getCardText(meta);

        return (
            <Card
                className={"ClipGrid"} tabIndex="0"
                onKeyUp={this.onKeyUpCard(() => this.onDetail(clip.getId()))}
                onKeyPress={(evt) => this.onKeyPress(evt, clip.getId())}
                onClick={() => this.onDetail(clip.getId())}>
                <div className={"ImageGridContainer"}>
                    {thumb}
                </div>
                <div className={"CardControls"}>
                    <div className={"hover"}>
                        {process.env.REACT_APP_SERVER !== "EXT" && this.props.controls}
                    </div>
                </div>
                <div className={"CardOverlay"}>
                    {text}
                    <div className={"hover"}>
                        <p>{meta.fields["summary"] || ""}</p>
                    </div>
                </div>
            </Card>
        );
    }
    
    componentDidUpdate() {
        //console.log("componentDidUpdate");
    }
}

export default withRouter(ClipHCard);
