import { Row, Col, Form, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Component } from 'react';
import { observer } from "mobx-react";
import { injectIntl } from 'react-intl';
import store from 'index';

import { reaction, autorun } from "mobx";

import { allEmpty, decodeDate, encodeDate, createFromDate, createToDate, splitDate } from "Util/Date.js";

import './Filter.scss';

const DateFilter = observer(
    class DateFilter extends Component {

        defaultDates = {
            from: { day: "", month: "", year: "" },
            to: { day: "", month: "", year: "" },
        };

        state = {
            open: false
        };

        store = store.filterStore;

        constructor(props) {
            super(props);
            this.store.registerFilter(this.props.field, this.props.createSearch || this.createSearch);
			this.store.getFilter(this.props.field).setOptional({ ...this.init(props) });

            this.state.field = this.props.field;
            this.state.open = props.open || false;

            reaction(() => this.store.getFilter(this.state.field).isClear(), this.clear);
        }

        static getDerivedStateFromProps(props, state) {
            return null;
        }

        componentDidMount = () => {
           
            
        }

        componentDidUpdate = (prevProps, prevState, snapshot) => {
			// TODO: 
			// Reactivate the algorithm for automatic search
            // if all fields empty >> start new search
            /*const stateAllEmpty = allEmpty(this.state.to) && allEmpty(this.state.from);
            const prevStateAllEmpty = allEmpty(prevState.to) && allEmpty(prevState.from);
            if (stateAllEmpty && !prevStateAllEmpty) {
                this.onSearch(this.state.from, this.state.to);
            }*/
        }

		init = (props) => {
            const filter = this.store.getFilter(props.field).getValues();
            const state = (filter.length && splitDate(filter[0])) || { ...this.defaultDates };
            return state;
        }

		clear = (isClear) => {
            if (isClear) {
                this.store.getFilter(this.props.field).setOptional({ ...this.defaultDates });
				this.store.getFilter(this.state.field).cleared();
            }
        }

        toggle = () => {
            this.setState({ open: !this.state.open });
        }

        //ACCESSIBILITY BEGIN
        onKeyUpHeader = (e) => {
            if (e.key === 'Enter') this.toggle();
        }
        //ACCESSIBILITY END

        onSearch = (from, to) => {
            let filter = [];
            if (!allEmpty(to) || !allEmpty(from)) {
                filter = [encodeDate(from) + "-" + encodeDate(to)];
            }
            const search = (this.props.createSearch && this.props.createSearch({ from: from, to: to })) || this.createSearch({ from: from, to: to });

            this.store.getFilter(this.state.field).updateData({ filter: filter, search: search });
        }

        createSearch = (filter) => {
            let dates = { ...this.defaultDates };

            if (Array.isArray(filter) && filter.length) {
                dates = splitDate(filter[0]);
            } else if (filter.from && filter.to) {
                dates.from = filter.from;
                dates.to = filter.to;
            }

            const search = [];
            const from = createFromDate(dates.from);
            const to = createToDate(dates.to);

            if (from && to) {
                //search.push({field: "", type: "BEGIN_AND", value: ""});
                search.push({ field: this.state.field, type: ">=", value: from });
                search.push({ field: this.state.field, type: "<=", value: to });
                //search.push({field: "", type: "END_AND", value: ""});
            } else if (from) {
                search.push({ field: this.state.field, type: ">=", value: from });
            } else if (to) {
                search.push({ field: this.state.field, type: "<=", value: to });
            }

            return search;
        }

        renderInfo = () => {
            const output = [];
            const from = createFromDate(this.state.from, 'dd.MM.yyyy');
            const to = createToDate(this.state.to, 'dd.MM.yyyy');

            if (from) { output.push(<div><FormattedMessage id={"common.from"} />: {from}</div>); }
            if (to) { output.push(<div><FormattedMessage id={"common.to"} />: {to}</div>); }
            return output;
        }
		
		onChange = (obj, key, value) => {
			const filter = this.store.getFilter(this.state.field);
			const optional = filter.getOptional();
			optional[obj] = { ...optional[obj], [key]: value };
			filter.setOptional(optional);
		}

        render() {
            const open = this.state.open ? <span className={"caret-up"} /> : <span className={"caret-down"} />;
			const optional = this.store.getFilter(this.state.field).getOptional();
            return (
                <Col className={"Filter"}>
                    <Row className={"Header"} onClick={this.toggle} tabIndex="0" onKeyUp={this.onKeyUpHeader}>
                        <Col>
                            <div className={"control"}>
                                <h5>{this.props.title}</h5>
                                {open}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col><Col className="Border"></Col></Col>
                    </Row>
                    {/*<Row className={!this.state.open+""}>
                        <Col>{this.renderInfo()}</Col>
                    </Row>*/}
                    <Row className={["filter-content", this.state.open].join(' ')}>
                        <Col>
                            <div className={"DateSearch"}>
                                <div className={"DateInput"}>
                                    <div><FormattedMessage id={"common.from"} />:</div>
                                    <Form.Control
                                        aria-label="from-day"
                                        value={optional.from.day}
                                        type="text"
                                        placeholder="DD"
                                        maxLength={2}
                                        onChange={(evt) => {
											optional.from.day = evt.target.value;
											this.store.getFilter(this.state.field).setOptional(optional);
										}} />
                                    <Form.Control
                                        aria-label="from-month"
                                        value={optional.from.month}
                                        type="text"
                                        placeholder="MM"
                                        maxLength={2}
                                        onChange={(evt) => {
											optional.from.month = evt.target.value;
											this.store.getFilter(this.state.field).setOptional(optional);
										}} />
                                    <Form.Control
                                        aria-label="from-year"
                                        value={optional.from.year}
                                        type="text"
                                        placeholder="YYYY"
                                        maxLength={4}
                                        onChange={(evt) => {
											optional.from.year = evt.target.value;
											this.store.getFilter(this.state.field).setOptional(optional);
										}} />
                                </div>
                                <div className={"DateInput"}>
                                    <div><FormattedMessage id={"common.to"} />:</div>
                                    <Form.Control
                                        aria-label="to-day"
                                        value={optional.to.day}
                                        type="text"
                                        placeholder="DD"
                                        maxLength={2}
                                        onChange={(evt) => {
											optional.to.day = evt.target.value;
											this.store.getFilter(this.state.field).setOptional(optional);
										}} />
                                    <Form.Control
                                        aria-label="to-month"
                                        value={optional.to.month}
                                        type="text"
                                        placeholder="MM"
                                        maxLength={2}
                                        onChange={(evt) => {
											optional.to.month = evt.target.value;
											this.store.getFilter(this.state.field).setOptional(optional);
										}} />
                                    <Form.Control
                                        aria-label="to-year"
                                        value={optional.to.year}
                                        type="text"
                                        placeholder="YYYY"
                                        maxLength={4}
                                        onChange={(evt) => {
											optional.to.year = evt.target.value;
											this.store.getFilter(this.state.field).setOptional(optional);
										}} />
                                </div>
                                <Button variant="primary" disabled={allEmpty(optional.from) && allEmpty(optional.to)} onClick={() => this.onSearch(optional.from, optional.to)}>
                                    <FormattedMessage id={"common.search"} />
                                </Button>
                            </div>
                        </Col>
                    </Row>

                </Col>
            );
        }
    }
)

export default DateFilter;
