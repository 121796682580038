import  { Row, Col, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl'
import { Component } from 'react';
import { observer } from "mobx-react";
import { injectIntl } from 'react-intl';

import CheckboxFilter from './CheckboxFilter';

import './Filter.scss';

const Decade = observer(
    class Decade extends Component {
        
        data = [
            { value:'1920-1929' },
            { value:'1930-1939' },
            { value:'1940-1949' }, 
            { value:'1950-1959' },
            { value:'1960-1969' },
            { value:'1970-1979' },
            { value:'1980-1989' },
            { value:'1990-1999' },
            { value:'2000-2009' },
            { value:'2010-2019' },
            { value:'2020-' + new Date().getFullYear() },
        ];
      
        state = {
            field: 'decade'
        };
        
        constructor(props) {
            super(props);
        }
        
        createSearch = (filter) => {
            let search = [];
            const selection = [];
            
            if(filter.length) {
                let from, to, index;
                
                filter.forEach(value => {
                    index = value.indexOf('-');
                    from = value.substring(0, index) + "-01-01";
                    to = value.substring(index+1) + "-12-31";
                    
                    
                    selection.push([
                        {field: 'broadcast_at', type:">=", value: from},
                        {field: 'broadcast_at', type:"<=", value: to}
                    ]);
                });
                
                if(selection.length) {
                    search = selection.length > 1 ? this.transformDistributiv(selection) : selection[0];
                } 
            }
            
            return search;
        }
        
        transformDistributiv = (selection) => {
            const search = [];
            let transform = [];
            
            // create array for all elements to push while transform
            let prepare = selection.shift();
            prepare.forEach(item => {
                transform.push([item]);
            });
            
            while(selection.length) {
                let tmp = [];
                prepare = selection.shift();
                
                // combine all transformed arrays with all items of prepare array
                transform.forEach(transformed => {
                    prepare.forEach(item => {
                        let transformedCopy = [...transformed];
                        transformedCopy.push(item);
                        tmp.push(transformedCopy);
                    });
                });
                
                transform = [...tmp];
            }
            
            transform.forEach(transformed => {
                search.push({field: "", type: "BEGIN_OR", value: ""});
                transformed.forEach(rule => {
                    search.push(rule);
                });
                search.push({field: "", type: "END_OR", value: ""});
            });
            
            return search;
        }
        
        /*getCount = (item, facetCount, checked) => {
            if(facetCount) {
                let count = 0; 
                checked.forEach(i => {
                    if(item.value !== i.value) {
                        count += parseInt((facetCount[this.state.field] && facetCount[this.state.field][i.value.substring(0,3)])) || 0;
                        
                    }
                });
                
                let val = item.value.substring(0,3);
                count += parseInt(facetCount[this.state.field] && facetCount[this.state.field][val]) || 0;
                return count;
            }
            return 0;
        }*/
		
		/*getCount = (item, facetCount, checked) => {
            if(facetCount) {
                let count = 0; 
                checked.forEach(i => {
                    if(item.value !== i.value) {
						count += parseInt((facetCount[this.state.field] && facetCount[this.state.field][i.value.substring(0,3)])) || 0;
                    }
                });
                
                let val = item.value.substring(0,3);
				if(parseInt(facetCount[this.state.field] && facetCount[this.state.field][val])) {
					return count + parseInt(facetCount[this.state.field][val]);
				}
            }
            return 0;
        }*/
		
		getCount = (item, facetCount, checked) => {
            if(facetCount) {
                let val = item.value.substring(0,3);
				if(parseInt(facetCount[this.state.field] && facetCount[this.state.field][val])) {
					return parseInt(facetCount[this.state.field][val]);
				}
            }
            return 0;
        }

        render() {
            return <CheckboxFilter 
                {...this.props }
                data={this.data}
                field={this.state.field}
                title={this.props.intl.formatMessage({id: "filter.decade"})}
                createSearch={this.createSearch}
                facetCount={(this.props.facetCount && this.props.facetCount[this.state.field]) || false}
                getCount={this.getCount}/>;
        }
    }
)

export default injectIntl(Decade);

/*
const Decade = observer(
    class Decade extends Component {
        
        data = [
            { value:'1920-1929' },
            { value:'1930-1939' },
            { value:'1940-1949' }, 
            { value:'1950-1959' },
            { value:'1960-1969' },
            { value:'1970-1979' },
            { value:'1980-1989' },
            { value:'1990-1999' },
            { value:'2000-2009' },
            { value:'2010-2019' }
        ];
      
        state = {
            filter: [],
            field: 'decade',
            from: { day: "", month: "", year: "" },
            to: { day: "", month: "", year: "" },
            open: false
        };
        
        constructor(props) {
            super(props);
            this.state.open = props.open || false;
            this.state.filter = (props.filter && props.filter[this.state.field] && props.filter[this.state.field].filter) || [];
        }
        
        static getDerivedStateFromProps(props, state) {
            // TODO: refactor - always a different object
            if(!Object.is(props.filter, state.filter)) {
                return {
                    filter: (props.filter && props.filter[state.field] && props.filter[state.field].filter) || []
                }
            }
            return null;
        }
        
        toggle = () => {
            this.setState({ open: !this.state.open });
        }
        
        onChange = (id, value) => {
            let filter = this.state.filter
            let i = filter.indexOf(value);
            
            if(i !== -1) {
                filter.splice(i, 1);
            } else {
                filter.push(value);
            }
            
            this.setState({ filter: filter });
            
            const search = this.createSearch(filter);
            this.props.setFilter({ [this.state.field]: { filter: filter, search: search } });
        }
        
        createSearch = (filter) => {
            const search = [];
            
            if(filter.length) {
                search.push({field: "", type: "BEGIN_OR", value: ""});
                
                filter.forEach(value => {
                    search.push({field: this.state.field, type:"=", value: value});
                });
                
                search.push({field: "", type: "END_OR", value: ""});
            }
            
            return search;
        }
        
        getChecked = () => {
            const data = this.data.filter(item => {
                return this.state.filter.includes(item.value);
            });
            return data;
        }
        
        getNotChecked = () => {
            const data = this.data.filter(item => {
                return !this.state.filter.includes(item.value);
            });
            return data;
        }
        
        renderOutput = (data, checked = false) => {
            const output = data.map(item => {
                let id = item.value.replace(" ", "").toLowerCase();
                return (
                    <Col className={"content-row"} key={id + checked} md={12}>
                        <Form.Check 
                            type={"checkbox"}
                            id={id}
                            defaultChecked={checked}
                            label={item.value}
                            onChange={() => {this.onChange(id, item.value);}}
                        />
                    </Col>
                );
            });
            return output;
        }

        render() {
            const checked = this.renderOutput(this.getChecked(), true);
            const notChecked = this.renderOutput(this.getNotChecked());
            const output = checked.concat(notChecked);
            
            const open = this.state.open ? <span className={"caret-up"} /> : <span className={"caret-down"} />;
            
            return (
                <Col className={"Filter"}>
                    <Row className={"Header"} onClick={this.toggle}>
                        <Col>
                            <div className={"control"}>
                                <h5><FormattedMessage id={"filter.decade"} /></h5>
                                { open }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col><Col className="Border"></Col></Col>
                    </Row>
                    <Row className={!this.state.open+""}>
                        <Col>{this.state.filter.join(', ')}</Col>
                    </Row>
                    <Row className={["filter-content", this.state.open].join(' ')}>
                        { output }
                    </Row>

                </Col>
            );
        }
    }
)

export default Decade;
*/
