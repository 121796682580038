import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"
import { withRouter } from "react-router-dom";

import {
    Switch,
    Route
} from "react-router-dom";

import { IntlProvider, } from 'react-intl';
import { messages } from './index'

import { Spinner, Row, Col } from 'react-bootstrap';

import ErrorBoundary from 'Util/ErrorBoundary';

import Header from 'Components/Header';
import Sidebar from 'Components/Sidebar';
import HorizontalBar from 'Components/HorizontalBar';

import Home from 'Pages/Home';
import Search from 'Pages/Search';
import Detail from 'Pages/Detail';
import Login from 'Pages/Login';
import Playlist from 'Pages/Playlist';
import Account from 'Pages/Account';

import { loginStandard, sessionLogin } from 'Util/Login';
import { setURL } from "Util/URL";

import PlaylistModal from 'Components/Modal/PlaylistModal';
import ContentWarningModal, { isLoading, renderTitle, renderLink, renderText, renderCheck } from 'Components/Modal/ContentWarningModal';

import * as Session from "Util/Session";

import { withCookies } from 'react-cookie';

import { FormattedMessage, injectIntl } from 'react-intl'
import parse from 'html-react-parser';
import { _LANG_EN_, _LANG_CY_, CONTENT_TYPE_WARNING } from "Config/constants";

import './App.scss';

import store from 'index';

const App = observer(
    class App extends Component {
        
        constructor(props) {
            super(props);
            
            const { cookies } = props;
            console.log(cookies.get('__ac'));

            this.state = {
                flowconnected: false,
                realUser: false,
                lang: 'en',
                url: "",
                sessionCheck: false,
                // implement show modal global in App.js
                showPlaylistModal: false,
                contentWarning: {
                    show: false,
                    disabled: true,
                    failed: false,
                    enable: () => {
                        const cw = { ...this.state.contentWarning }
                        cw.disabled = !cw.disabled;
                        this.setState({ contentWarning: cw });
                    },
                    fail: (val) => {
                        const cw = { ...this.state.contentWarning }
                        cw.failed = val;
                        this.setState({ contentWarning: cw });
                    }
                }
            };
        }

        static checkContentWarning(props, state) {
            const contentWarning = { ...state.contentWarning };
            
            const { cookies } = props;
            const ac = cookies.get('__ac');
            
            if(!ac || !ac.agreed) {
                contentWarning.show = true;
            }
            
            return { contentWarning: contentWarning };
        }

        static getDerivedStateFromProps(props, state) {
            return App.checkContentWarning(props, state);
        }

        //ACCESSIBILITY BEGIN
        addNavParent = ({ id = null, className = false }) => {
            if (id || className) {
                let el;
                if (id) el = document.getElementById(id);
                else if (className) el = document.querySelector(`.${className}`);
                let parent = el.parentNode;
                let wrapper = document.createElement('li');
                wrapper.classList.add('nav-wrapper');
                parent.replaceChild(wrapper, el);
                wrapper.appendChild(el);
            }
        }

        addNavItemClass = ({ id = null, className = false }) => {
            if (id || className) {
                let el;
                if (id) el = document.getElementById(id);
                else if (className) el = document.querySelector(`.${className}`);
                if (el) {
                    let parent = el.parentNode;
                    if (parent) parent.classList.add('nav-wrapper');
                }
            }
        }

        checkAndUpdateAccessNav = () => {
            const listItemElements = document.querySelectorAll("li.nav-item a");
            if (listItemElements && listItemElements.length) {
                for (let listItemEl of listItemElements) {
                    listItemEl.setAttribute("tabindex", "0");
                }
            }

            this.addNavItemClass({ id: "llgc_mobile_lang_switch" });
        }

        checkAccessFooter = () => {
            const imgElements = document.querySelectorAll(".llgc_footer_logos img");
            if (imgElements && imgElements.length) {
                for (let imgEl of imgElements) {
                    if (imgEl.getAttribute("alt") && imgEl.getAttribute("title")) {
                        imgEl.removeAttribute("alt");
                    }
                }
            }
        }

        checkAccess = () => {
            this.checkAndUpdateAccessNav();
            this.checkAccessFooter();
        }
        //ACCESSIBILITY END

        componentDidMount = () => {
            const { history, location, match } = this.props;
            store.searchStore.setRouter({ history, location, match });

            window.flow = this.props.flow;
            window.flow.init().then(() => {
                //ACCESSIBILITY BEGIN
                this.checkAccess();
                //ACCESSBILITY END

                this.setState({ flowconnected: true });
                store.init();
                if (!this.state.realUser) {
                    this.setState({ sessionCheck: true });
                    sessionLogin({ onLogin: this.setRealUser });
                }
            });
            this.props.flow.addEventListener(window.felib.main.event.LOGOUT, this.flowLoggedOut);
        }

       
        componentDidUpdate = (prevProps, prevState, snapshot) => {
            Session.setSessionTimeout();
            if (prevState.clearFilter) {
                this.setState({ clearFilter: false });
            }
        }

        setShowModal = (show) => {
            this.setState({ showPlaylistModal: show });
        }

        /*checkCookie = () => {
            console.log(this.props);
            const { cookies } = this.props;
            cookies.set('__ac', { agreed: true }, { path: '/' })
            console.log(cookies.get('CookieControl'));
            console.log(cookies.get('__ac'));
            //console.log(document.cookie.split(';'));
        }*/

        setRealUser = (user, session = false) => {
            this.setState({ realUser: user, sessionCheck: false });
            if (user) {
                store.accountStore.setUser(user);
                Session.startSessionCheck({ onLogout: this.onLogout });
                
                store.accountStore.loadPlaylists();
                if (!session) {
                    this.props.history.goBack();
                }
            } else {
                store.accountStore.unsetUser();
            }
        }


        onLogout = () => {
            store.accountStore.unsetUser();
            Session.stopSessionCheck();
            this.setState({ realUser: false, playlists: [] });
        }


        setUser = (user) => {
            this.setState({ user: user });
        }

        login = () => {
            loginStandard({ setUser: this.setUser });
        }

        flowLoggedOut = () => {
            this.onLogout();
        }

        renderModal = () => {
            return (
                <ContentWarningModal
                    {...this.props}
                    config={this.state.contentWarning}
                    show={this.state.contentWarning.show}
                    backdrop="static"
                    keyboard={false}
                    loading={isLoading()}
                    title={renderTitle()}
                    text={renderText()}
                    link={renderLink(this.props)}
                    check={renderCheck()}
                    onHide={() => {
                        const cw = { ...this.state.contentWarning }
                        cw.show = false;
                        this.setState({ contentWarning: cw });
                        
                    }}
                />
            );
            if (!this.state.showPlaylistModal || !this.state.realUser) {
                return "";
            }
            return (<PlaylistModal
                {...this.props}
                user={this.state.realUser}
                show={this.state.showPlaylistModal}
                onHide={() => this.setShowModal(false)}
            />
            );
        }
        
        

        renderContent = () => {
            if (!this.state.flowconnected || this.state.sessionCheck) {
                return this.renderLoading();
            }
            return this.renderSwitch();
        }

        renderLoading = () => {
            return (
                <Col className="full-height text-center">
                    <Spinner animation="border" variant="primary" />
                </Col>
            );
        }

        renderSwitch = () => {

            return (
                <Switch>
                    <Route path={"/search"} >
                        <Sidebar
                            setFilter={this.setFilter}
                            setSearch={this.setSearch}
                            clearFilter={this.clearFilter}
                            fulltext={this.state.within}
                            search={this.state.search}
                            filter={this.state.filter}
                            clear={this.state.clearFilter}
                            facetCount={this.state.facetCount} />
                        <HorizontalBar
                            setFilter={this.setFilter}
                            setSearch={this.setSearch}
                            clearFilter={this.clearFilter}
                            fulltext={this.state.within}
                            search={this.state.search}
                            filter={this.state.filter}
                            clear={this.state.clearFilter}
                            viewmode={this.state.viewmode}
                            setViewMode={this.setViewMode} />
                        <Search
                            user={this.state.realUser}
                            setSearch={this.setSearch}
                            search={this.state.search}
                            fulltext={this.state.fulltext}
                            within={this.state.within}
                            filter={this.state.filter}
                            results={this.state.results}
                            sorting={this.state.sorting}
                            page={this.state.page}
                            setPage={this.setPage}
                            viewmode={this.state.viewmode}
                            setResults={this.setResults}
                            setViewMode={this.setViewMode}
                            setSorting={this.setSorting}
                            setFacetCount={this.setFacetCount}
                            playlists={this.state.playlists}
                            showPlaylistModal={this.setShowModal} />
                    </Route>
                    {
                        process.env.REACT_APP_SERVER !== "EXT" && this.state.realUser &&
                        <Route exact path="/account">
                            <Account
                                user={this.state.realUser} />
                        </Route>
                    }
                    {
                        process.env.REACT_APP_SERVER !== "EXT" && this.state.realUser &&
                        <Route exact path="/playlist">
                            <Playlist
                                user={this.state.realUser}
                                data={this.state.playlists}
                                setPlaylists={this.setPlaylists} />
                        </Route>
                    }
                    {
                        process.env.REACT_APP_SERVER !== "EXT" &&
                        <Route exact path="/login">
                            <Login
                                setProjects={this.setProjects}
                                onLogin={this.setRealUser}
                            />
                        </Route>
                    }
                    <Route exact path="/detail/:id">
                        <Detail
                            user={this.state.realUser}
                            results={this.state.results}
                            page={this.state.page}
                            playlists={this.state.playlists}
                            showPlaylistModal={this.setShowModal} />
                    </Route>
                    <Route path="/" >
                        <Home />
                    </Route>
                </Switch>
            );
        }

        render() {
            // set correct urls in navigation menu
            setURL(this.props.location);

            const sidebar = (
                <Sidebar
                    setFilter={this.setFilter}
                    setSearch={this.setSearch}
                    clearFilter={this.clearFilter}
                    fulltext={this.state.within}
                    search={this.state.search}
                    filter={this.state.filter}
                    clear={this.state.clearFilter}
                    facetCount={this.state.facetCount} />
            );

            return (
                <IntlProvider
                    messages={messages[window._LANG_]}
                    locale={'en'}
                    defaultLocale="en">
                    <ErrorBoundary>
                        {/*<Container className={"container-main"}>*/}
                        <div className="hidden">
                            {sidebar}
                        </div>
                        <Header
                            user={this.state.realUser}
                            onLogout={this.onLogout}
                            flowconnected={this.state.flowconnected}
                            sessionCheck={this.state.sessionCheck}
                        />
                        <Row className={"content"}>

                            {this.renderContent()}

                        </Row>

                        {this.renderModal()}

                        {/*</Container>*/}
                    </ErrorBoundary>
                </IntlProvider>
            );
        }
    }
)

export default Flow.injectFlow(withRouter(withCookies(App)));
