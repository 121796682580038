import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { injectIntl } from 'react-intl';

import { Row, Col, Form, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTh, faList, faStar } from '@fortawesome/free-solid-svg-icons'

import ClipGrid from 'Components/ClipGrid';
import ClipList from 'Components/ClipList';

import Pagination from 'Components/Pagination';
import PlaylistPanel from 'Components/PlaylistPanel';

import PlaylistClipModal from 'Components/Modal/PlaylistClipModal';

import { VIEW_MODE_GRID, VIEW_MODE_LIST } from 'Config/constants';

import * as PlaylistUtil from 'Util/Playlist';

import './Playlist.scss';

import store from 'index';

const Playlist = observer(
    class Playlist extends Component {

        constructor(props) {
            super(props);
        }

        componentDidMount() {
            if (!store.accountStore.index) {
                store.accountStore.setCurrent(0);
            }
			window.scrollTo(0,0);
        }

        static getDerivedStateFromProps(props, state) {
            return null;
        }

        componentDidUpdate(prevProps, prevState) {

        }

        back = () => {
            this.props.history.push({
                pathname: '/search/',
                search: store.searchStore.createUrl()
            });
        }

        onKeyUpRemovePlaylist = (cb) => (e) => {
            if (e.key === 'Enter') cb();
        }

        removeFromList = (evt, id, playlist, isFocus = false) => {
            if (!isFocus) {
                evt.preventDefault();
                evt.stopPropagation();
            }
            if (!playlist) return false;

            playlist.remove(id);

            return true;
        }

        getClips = (playlist) => {
            if (!playlist) return [];

            const Clip = store.uiStore.viewmode === VIEW_MODE_GRID ? ClipGrid : ClipList;

            return playlist.getClips().map(clip => {
                let controls = [
                    <span className="removeplaylist-icon" tabIndex="0"
                        onKeyUp={this.onKeyUpRemovePlaylist((evt) => this.removeFromList(evt, clip.id, playlist, true))}>
                        <FontAwesomeIcon
                            icon={faStar}
                            onClick={(evt) => this.removeFromList(evt, clip.id, playlist)}
                            title={this.props.intl.formatMessage({ id: "button.playlist.clip.remove" })} /></span>
                ];

                return (<Clip key={clip.id} data={clip} ids={[]/*this.state.currentList.ids*/} controls={controls} />);
            });
        }


        render() {
            const playlist = store.accountStore.getCurrentPlaylist();

            const count = (playlist && playlist.getCount()) || 0;
            const resultStart = (playlist && count) ? 1 : 0;
            let resultEnd = (playlist && count) || 0;

            if (resultEnd > count) {
                resultEnd = count;
            }

            let resultText = (<FormattedMessage id="page.playlist.viewing" values={{ from: resultStart, to: resultEnd, results: count }} />);
            if (!count) {
                resultText = (<FormattedMessage id="page.playlist.viewing.zero" />);
            }

            return (
                <>
                    <PlaylistPanel {...this.props} />
                    <Col className={"PlaylistPage"} lg={12} xl={9}>
                        <Row className={"PageHeader"}>
                            <Col>
                                <h1>
                                    {
                                        (playlist && playlist.getName()) || <FormattedMessage id="page.playlist.selected.no" />
                                    }
                                </h1>
                                <p>{playlist && resultText}</p>
                            </Col>
                            <Col md="auto">
                                {/*<Pagination
                                    { ...this.props }
                                    count={count} />*/}
                            </Col>
                            <Col md="auto">
                                <Button variant="primary" onClick={this.back}>
                                    <FormattedMessage id="page.detail.button.back" />
                                </Button>
                            </Col>
                            <Col className="ViewMode" md="auto">
                                <Button
                                    className={'Grid'}
                                    variant={store.uiStore.viewmode === VIEW_MODE_GRID ? "dark" : "outline-dark"}
                                    onClick={() => store.uiStore.setViewMode(VIEW_MODE_GRID)}
                                >
                                    <FontAwesomeIcon icon={faTh} />
                                </Button>
                                <Button
                                    className={'List'}
                                    variant={store.uiStore.viewmode === VIEW_MODE_LIST ? "dark" : "outline-dark"}
                                    onClick={() => store.uiStore.setViewMode(VIEW_MODE_LIST)}
                                >
                                    <FontAwesomeIcon icon={faList} />
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            {this.getClips(playlist)}
                        </Row>
                    </Col>
                </>
            );
        }
    }
)

export default Flow.injectFlow(injectIntl(withRouter(Playlist)));
