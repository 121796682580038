import { FormattedMessage, injectIntl } from 'react-intl'
import { Form, Button } from 'react-bootstrap';
import { Link, withRouter } from "react-router-dom";


import Modal from 'react-bootstrap/Modal';

import store from 'index';

const PlaylistModal = (props) => {
    /*let title = (props.edit && props.edit.getName()) || "";
    let description = (props.edit && props.edit.getDescription()) || "";
    let id = -1;

    const onAdd = (title, description) => {
        store.accountStore.addPlaylist(title, description);
    }
    
    const onEdit = (title, description) => {
		props.edit.edit({ title: title, txt5: description });
    }
   
    const onSelect = (evt) => {
        if(evt.target.value) {
            id = parseInt(evt.target.value);
        }
    }
    
    const renderInput = () => {
        return (
            <Modal.Body>
                <Form.Control
                    type="text"
                    defaultValue={title}
                    placeholder={props.intl.formatMessage({id: "modal.playlist.placeholder.title" })} 
                    onChange={(evt) => title = evt.target.value}/>
                <Form.Control
                    type="text" 
                    defaultValue={description}
                    placeholder={props.intl.formatMessage({id: "modal.playlist.placeholder.description" })}
                    onChange={(evt) => description = evt.target.value}/>
            </Modal.Body>
        );
    }
    
    let modalTitle = (<FormattedMessage id="modal.playlist.new"/>);
    if(props.edit) { modalTitle = (<FormattedMessage id="modal.playlist.edit"/>); }
    
    let button = props.share ? <FormattedMessage id="common.share" /> : <FormattedMessage id="common.save"/>;
    */
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

	<Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2><FormattedMessage id="modal.register.description"/></h2>
        </Modal.Title>
	</Modal.Header>
       
      <Modal.Footer>
        <Button variant={"outline-dark"} onClick={props.onHide}><FormattedMessage id="common.close"/></Button>
        <Button
            variant={"dark"} 
            onClick={(evt) => {
                props.onHide();
				props.history.push({ pathname: '/login', search: store.searchStore.createUrl()  });
            }}>
				<FormattedMessage id="header.nav.login"/>
			</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default injectIntl( withRouter(PlaylistModal) );
