import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react";
import { injectIntl } from 'react-intl';
import { withRouter } from "react-router-dom";

import { Spinner, Row, Col, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import { login } from 'Util/Login';

import { ldapDate } from 'Util/Date';

import './Account.scss';

const Account = observer(
    class Account extends Component {
        state = {
            visibility: "password"
        }
		
		componentDidMount() {
			window.scrollTo(0,0);
        }
        
        render() {
            const linkChange = this.props.intl.formatMessage({id: "page.account.link.change" });
            const linkUpdate = this.props.intl.formatMessage({id: "page.account.link.update" });

            return (
                <Col className={"AccountPage"}>
                    <Row>
                        <Col className="AccountContent">
                            <Row className={"PageHeader"}>
                                <Col><h1><FormattedMessage id="header.nav.account" /></h1></Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={6}>
                                    <div>
                                        <h3 className={"Label"}><FormattedMessage id="common.name" /></h3>
                                        <p className={"Text"}>{this.props.user.first_name} {this.props.user.last_name}</p>
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div>
                                        <h3 className={"Label"}><FormattedMessage id="common.email" /></h3>
                                        <p className={"Text"}>{this.props.user.email}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={6}>
                                    <div>
                                        <h3 className={"Label"}><FormattedMessage id="page.account.login" /></h3>
                                        <p className={"Text"}>{this.props.user.id}</p>
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div>
                                        <h3 className={"Label"}><FormattedMessage id="page.account.valid" /></h3>
                                        <p className={"Text"}>{ldapDate(this.props.user.date)}</p>
                                    </div>
                                </Col>
                            </Row>
                            
                            <Row className={"PageHeader"}>
                                <Col>
                                    <p>
                                        <FormattedMessage 
                                            id={"page.account.text.change"} 
                                            values={{
                                                a: msg => <a href={linkChange}>{msg}</a>
                                            }}
                                        />
                                    </p>
                                    <p>
                                        <FormattedMessage 
                                            id={"page.account.text.update"} 
                                            values={{
                                                a: msg => <a href={linkUpdate}>{msg}</a>
                                            }}
                                        />
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row >
                </Col>
            );
        }
    }
)

export default Flow.injectFlow(injectIntl(withRouter(Account)));
