import  { Row, Col, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl'
import { Component } from 'react';
import { observer } from "mobx-react";
import { injectIntl } from 'react-intl';

import CheckboxFilter from './CheckboxFilter';

import './Filter.scss';

const Publish = observer(
    class Publish extends Component {
        
        // this Component negate the value ( != ) so label and value differ from each other
        
        data = [ 
            /*{ value:'published_internal', label: this.props.intl.formatMessage({id: "filter.publish.public"}) }, 
            { value:'published_public', label: this.props.intl.formatMessage({id: "filter.publish.internal"}) }*/
            { value:'published_internal', label: this.props.intl.formatMessage({id: "filter.publish.internal"}) },
			{ value:'published_public', label: this.props.intl.formatMessage({id: "filter.publish.public"}) }
        ];
      
        state = {
            field: 'published'
        };
        
        constructor(props) {
            super(props);
        }
        
        createSearch = (filter) => {
            const search = [
				/*{field: "", type: "BEGIN_OR", value: ""},
				{field:"status",type:"=",value:"published_internal"},
				{field:"status",type:"=",value:"published_public"},
				{field: "", type: "END_OR", value: ""}*/
			];
            if(filter.length) {
                search.push({field: "", type: "BEGIN_OR", value: ""});
                
                filter.forEach(value => {
                    search.push({field: "status", type:"=", value: value});
                });
                
                search.push({field: "", type: "END_OR", value: ""});
            } else {
				search.push({field: "", type: "BEGIN_OR", value: ""});
				search.push({field:"status",type:"=",value:"published_internal"});
				search.push({field:"status",type:"=",value:"published_public"});
				search.push({field: "", type: "END_OR", value: ""});
			}
            
            return search;
        }

        render() {
            return (
                <CheckboxFilter 
                    {...this.props } 
                    data={this.data} 
                    field={this.state.field}
                    title={this.props.intl.formatMessage({id: "filter.publish"})}
                    createSearch={this.createSearch}/>
            );
        }
    }
)

export default injectIntl(Publish);
