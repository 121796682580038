import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { injectIntl } from 'react-intl';

import { FormattedMessage } from 'react-intl'
import { Spinner, Row, Col, Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTh, faList, faStar as faStarSolid, faCheckCircle, faStar } from '@fortawesome/free-solid-svg-icons'
import { faStar as faStarRegular, faCircle } from '@fortawesome/free-regular-svg-icons'

import ClipGrid from 'Components/ClipGrid';
import ClipList from 'Components/ClipList';
import Pagination from 'Components/Pagination';
import DisplayOptions from 'Components/DisplayOptions';

import PlaylistClipModal from 'Components/Modal/PlaylistClipModal';
import RegisterModal from 'Components/Modal/RegisterModal';

import { extendFulltextSearch } from 'Util/Search'
import * as Metadata from 'Util/Metadata';
import { METADATA_TYPE_CLIP } from 'Config/constants';

import { createURL } from "Util/URL";

import { VIEW_MODE_GRID, VIEW_MODE_LIST, SORTING_DIRECTION_ASC, SORTING_DIRECTION_DESC } from 'Config/constants';

import store from 'index';

import SearchInput from 'Components/Search';


import './Search.scss';

const Search = observer(
    class Search extends Component {

        state = {}

        filterStore = store.filterStore;
        searchStore = store.searchStore;
        uiStore = store.uiStore;
        accountStore = store.accountStore;

        componentDidMount() {
            this.searchStore.checkSearch();
        }

        //ACCESSIBILITY BEGIN
        changeAccessTitle = ({ changed = false }) => {
            const titleEl = document.querySelector("title");
            let headerTitle = this.props.intl.formatMessage({ id: "header.title" });
            if (changed) {
                let searchTitle = this.props.intl.formatMessage({ id: "search.toolbar.title.results" });
                searchTitle += " " + this.renderFilter();
                titleEl.textContent = `${headerTitle}: ${searchTitle}`;
            } else {
                titleEl.textContent = headerTitle;
            }
        }

        componentWillUnmount() {
            this.changeAccessTitle({});
        }
        //ACCESSIBILITY END

        static getDerivedStateFromProps(props, state) {
            if (state.search !== props.search) {
                return {
                    search: props.search
                }
            }
            return null;
        }

        componentDidUpdate(prevProps, prevState) { }

        renderFilter = () => {
            /*
            if (!this.searchStore.fulltext && !this.searchStore.within) { return "*.*"; }
            if (this.searchStore.fulltext && this.searchStore.within) { return this.searchStore.fulltext + " & " + this.searchStore.within }
            if (this.searchStore.fulltext) { return this.searchStore.fulltext; }
            if (this.searchStore.within) { return this.searchStore.within; }
            return "*.*";
            */
            return this.searchStore.output || "*.*";
        }

        onKeyUpAddPlaylist = (cb) => (e) => {
            if (e.key === 'Enter') cb();
        }

        getClips = () => {
            const resultClips = this.searchStore.getClips();
            const order = this.searchStore.getOrder();

            let clips = [];
            let controls = [];

            if (resultClips.length) {
                const Clip = this.uiStore.getViewMode()/*this.props.viewmode*/ === VIEW_MODE_GRID ? ClipGrid : ClipList;
                const metadata = Metadata.getMetadataObjects(METADATA_TYPE_CLIP);

                clips = resultClips.map(clip => {
                    // if(this.props.user) {

                    let callback = (evt) => {
                        this.setShowModal(true, evt, clip.id);
                    }
                    if (!this.props.user) {
                        callback = (evt) => {
                            this.setShowRegisterModal(true, evt);
                        }
                    }

                    let icon = faStarRegular;
                    let cls = "";
                    if (store.accountStore.isClipUsed(clip.id)) {
                        icon = faStar;

                    }
                    controls = [
                        <span className="SearchStarIcon" tabIndex="0"
                            onKeyUp={this.onKeyUpAddPlaylist((evt) => callback(evt))}><FontAwesomeIcon
                                className={cls}
                                icon={icon}
                                onClick={(evt) => callback(evt)/*this.setShowModal(true, evt, clip.id)*/}
                                title={this.props.intl.formatMessage({ id: "button.playlist.clip.add" })} /></span>
                    ];
                    //  }

                    return (
                        <Clip
                            key={clip.id}
                            data={clip}
                            metadata={metadata}
                            ids={order}
                            controls={controls} />
                    );
                });
            }

            return clips;
        }

        setShowModal = (show, evt, id = -1) => {
            if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
            }
            this.setState({ showModal: show, playlistClipId: id });
        }

        setShowRegisterModal = (show, evt) => {
            if (evt) {
                evt.preventDefault();
                evt.stopPropagation();
            }
            this.setState({ showRegisterModal: show });
        }

        renderResult = () => {
            if (this.searchStore.searching) {
                return <FormattedMessage id="search.toolbar.searching" />
            }
            if (!this.searchStore.getCount()) {
                return (<FormattedMessage id="search.toolbar.viewing.zero" />);
            }

            const count = this.searchStore.getCount() || 0;
            const offset = this.searchStore.getOffset();
            const limit = this.searchStore.getLimit();

            const resultStart = offset + 1;
            let resultEnd = limit + offset;


            if (resultEnd > count) {
                resultEnd = count;
            }

            //ACCESSIBILITY BEGIN
            this.changeAccessTitle({ changed: true });
            //ACCESSIBILITY END

            return (
                <FormattedMessage id="search.toolbar.viewing" values={{ from: resultStart, to: resultEnd, results: count }} />
            );
        }

        renderLoading = () => {
            return (
                <Col className="full-height text-center clip-list-loading">
                    <Spinner animation="border" variant="primary" />
                </Col>
            );
        }

        render() {
            window.scrollTo(0, 0);
            const output = this.searchStore.searching ? this.renderLoading() : this.getClips();
            const count = 0;

            return (
                <>
                    <Col className={"SearchPage"} lg={9} xl={9}>
                        <Row className={"PageHeader"}>
                            {/* <Col md={"auto"}> */}
                            <Col>
                                <h1><FormattedMessage id="search.toolbar.results" /></h1>
                                <p>
                                    {this.renderResult()/*resultText*/}
                                    <span className="SearchTerm">: {/*this.renderFilter()*/}</span>
                                </p>
                            </Col>
                            <Col className="SearchHeaderInput">
                                <SearchInput
                                    {...this.props}
                                    setValue={this.searchStore.setWithin}
                                    getValue={this.searchStore.getWithin}
                                    onSearch={this.searchStore.onWithinSearch}
                                    placeholder={"search.input.clip"}
                                    field={"within"} />

                            </Col>
                            {/* <Col md={"auto"}> */}
                            <Col className="SearchHeaderLeft">
                                <Row>
                                    <Col></Col>
                                    {/* <Col md={"auto"} className={"d-none d-lg-flex"}>
                                        <DisplayOptions {...this.props} />
                                    </Col> */}
                                    <Col md={"auto"} className={"SearchDisplayOptions d-none d-lg-flex"}>
                                        <DisplayOptions {...this.props} />
                                    </Col>
                                    <Col className="ViewMode d-none d-lg-flex" md="auto">
                                        <Button
                                            aria-label="grid"
                                            className={"Grid"}
                                            variant={this.uiStore.getViewMode()/*this.props.viewmode*/ === VIEW_MODE_GRID ? "dark" : "outline-dark"}
                                            onClick={() => this.uiStore.setViewMode(VIEW_MODE_GRID)/*this.props.setViewMode(VIEW_MODE_GRID)*/}
                                        >
                                            <FontAwesomeIcon icon={faTh} />
                                        </Button>
                                        <Button
                                            aria-label="list"
                                            className={"List"}
                                            variant={this.uiStore.getViewMode()/*this.props.viewmode*/ === VIEW_MODE_LIST ? "dark" : "outline-dark"}
                                            onClick={() => this.uiStore.setViewMode(VIEW_MODE_LIST)/*this.props.setViewMode(VIEW_MODE_LIST)*/}
                                        >
                                            <FontAwesomeIcon icon={faList} />
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col></Col>
                                    <Col md="auto">
                                        <Pagination
                                            {...this.props}
                                            count={count}
                                            showResults={false} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="clip-list">
                            {output}
                        </Row>
                        <hr />
                        <Row className={"SearchFooter"}>
                            <Col>

                            </Col>
                            <Col md="auto">
                                <Pagination
                                    {...this.props}
                                    count={count} />
                            </Col>
                        </Row>
                    </Col>

                    <PlaylistClipModal
                        show={this.state.showModal}
                        onHide={(evt) => this.setShowModal(false, evt)}
                        playlists={this.accountStore.playlists}
                        id={this.state.playlistClipId}
                        showPlaylistModal={this.props.showPlaylistModal} />

                    <RegisterModal
                        show={this.state.showRegisterModal}
                        onHide={(evt) => this.setShowRegisterModal(false, evt)} />

                </>
            );
        }
    }
)

export default Flow.injectFlow(injectIntl(withRouter(Search)));
