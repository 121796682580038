import { format } from 'date-fns';
import { cy, enGB } from 'date-fns/locale';
import { _LANG_EN_, _LANG_CY_ } from "Config/constants";
import HTMLParser from 'html-react-parser';

import MediaOutputExternal from 'Components/MediaOutput/external/MediaOutput.js';
import MediaOutputInternal from 'Components/MediaOutput/internal/MediaOutput.js';

export const isValid = (item, meta) => {
    let field = meta.fields[item];
    if(field && item === "broadcast_at") {
        let date = new Date(field);
        
        if (date == 'Invalid Date') {
            return false;
        }
    }
    
    if(field && item === "duration") {
        let time = field.split(':');
        
        for(let i = 0; i < time.length; i++) {
            if(parseInt(time[i])) {
                return true;
            }
        }
        return false;
    }
    return meta.fields[item];
}
    
export const getOutput = (item, meta) => {
    let field = meta.fields[item];
    if(field && item === "broadcast_at") {
        return format(new Date(field), 'd LLLL yyyy HH:MM', {locale: window._LANG_ === _LANG_EN_ ? enGB : cy});
    }
    
    if(field && item === "duration") {
        const times = ['h', 'm', 's', 'ms'];
        let time = field.split(':');
        let timestring = [];
        
        time.pop(); // last item is milliseconds (no need to show
        time.forEach((t, i) => {
            if(parseInt(t)) {
                timestring.push(parseInt(t) + times[i]);
            }
        });
        return timestring.join(' ');
    }
	
	if(field && item === "externallinks") {
		const links = field.split('\n');
		const output = links.map(link => {
			if(link.indexOf('http') !== -1) {
				return HTMLParser(link);
			}
			return <div>{link}</div>;
		});
		return output;
	}
    
    return field;
}

export const getMediaOutput = () => {
    return (process.env.REACT_APP_SERVER === "EXT" && MediaOutputExternal) || MediaOutputInternal;
}
