import { makeAutoObservable, action, runInAction, reaction } from "mobx"

import * as PlaylistUtil from 'Util/Playlist';

export default class AccountStore {

	rootStore = null;

	user = false;

	index = 0;
	playlists2 = [];

	constructor(rootStore) {
		makeAutoObservable(this, {
			rootStore: false
		});
		this.rootStore = rootStore;
	}

	setUser = (user = false) => {
		this.user = user;
	}

	unsetUser = () => {
		this.user = false;
		this.playlists2 = [];
		this.index = 0;
		this.rootStore.searchStore.replaceUrl();
	}

	getUser = () => {
		return this.user;
	}

	getCurrentPlaylist = () => {
		return (this.playlists2.length && this.playlists2[this.index]) || false;
	}

	getPlaylists = () => {
		return this.playlists2;
	}

	getPlaylist = (index) => {
		return this.playlists2[index];
	}

	getIndex = (index) => {
		return this.index;
	}


	loadPlaylists = () => {
		if (this.user) {
			const fields = [{ field: "txt2", type: '=', value: this.user.id }];
			window.flow.getDistprojectsForDist('nlw_playlist', 0, fields).then(res => {
				const arr = (res.objects && res.objects.asArray()) || [];

				this.playlists2 = [];
				arr.forEach(list => {
					this.playlists2.push(new Playlist(this, list));
				});
			}).catch(res => {
			});
		}
	}

	addPlaylist = (title, description) => {
		window.flow.newDistproject("nlw_playlist", 0, { title: title, txt2: this.user.id, txt5: description || "" }).then(res => {
			if (this.playlists2.length) {
				this.index += 1;
			} else {
				this.index = 0;
			}
			this.loadPlaylists();
			this.rootStore.searchStore.replaceUrl();
		}).catch(err => {
		});
	}


	removePlaylist = (id, index) => {
		let next = 0;
		if (this.index >= index) {
			next = -1;
		}
		window.flow.delDistproject(id).then(res => {
			if (this.index) {
				this.index += next;
			}
			this.loadPlaylists();
			this.rootStore.searchStore.replaceUrl();
		}).catch(res => {
		});
	}

	setCurrent = (index) => {
		this.index = index;
		this.rootStore.searchStore.replaceUrl();
	}

	isClipUsed = (id) => {
		const lists = [];
		this.playlists2.forEach(list => {
			if (list.getIds().includes(id)) {
				lists.push(list);
			}
		});

		return (lists.length && lists) || false;
	}

	init = () => {
		this.initPlaylist();
	}

	initPlaylist = () => {
		const params = new URL(document.location).searchParams.getAll("pl");
		if (params.length) {
			this.index = params[0];
		}
	}
}

export class Playlist {

	list = false;
	id = false;
	clips = [];
	ids = [];
	loading = false;

	store = null;

	constructor(store, list) {
		makeAutoObservable(this, {
			store: false
		});
		this.store = store;
		this.list = list;
		this.load();
	}

	createIds = () => {
		this.ids = this.clips.map(clip => {
			return clip.id;
		});
	}

	load = () => {
		this.loading = true;
		window.flow.getDistprojectById(this.list.id).then(res => {
			if (res.getClips().elements) {
				this.clips = res.getClips().elements;
				this.createIds();
			}
			this.loading = false;
		}).catch(res => {
			
		});
	}

	edit = (metadata) => {
		this.loading = true;
		window.flow.editDistproject(this.list, metadata).then(res => {
			this.load();
		}).catch(res => {
		});
	}

	add = (id) => {
		window.flow.distprojectAddClip(this.list, id).then(res => {
			this.load();
		}).catch(res => {
		});
	}

	remove = (id) => {
		window.flow.distprojectRemoveClipById(this.list, id).then(res => {
			this.load();
		}).catch(res => {
		});
	}

	getId = () => {
		return this.list.id;
	}

	getName = () => {
		return this.list.name;
	}

	getDescription = () => {
		return (this.list.metadata && this.list.metadata.fields && this.list.metadata.fields.txt5) || "";
	}

	getClips = () => {
		return this.clips;
	}

	getCount = () => {
		return this.clips.length;
	}

	getIds = () => {
		return this.ids;
	}
}

