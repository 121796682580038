import * as Metadata from "Config/Metadata";
import * as Const from "Config/constants";

const getMetadata = (type) => {
    switch(type) {
        case Const.METADATA_TYPE_CLIP:
            return Metadata[Const.METADATA_TYPE_CLIP];
        default:
            return [];
    }
}

export const getMetadataObjects = (metadata) => {
    const data = (Array.isArray(metadata) && metadata) || getMetadata(metadata);
    const objects = {};
    
    data.forEach(obj => {
        if(obj.field) {
            objects[obj.field] = obj;
        }
    });
    
    return objects;
}

export const getOptions = (type) => {
    
}
