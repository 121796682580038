import { FormattedMessage, injectIntl } from 'react-intl'
import { Form, Button } from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';

import parse from 'html-react-parser';

import Modal from 'react-bootstrap/Modal';

import { _LANG_EN_, _LANG_CY_, CONTENT_TYPE_WARNING } from "Config/constants";

import store from 'index';

export const isLoading = () => {
    return !store.uiStore.getContent(CONTENT_TYPE_WARNING);
}

const removeHtmlTag = (str, tag = "p") => {
    return str.replaceAll("<"+tag+">","").replaceAll("</"+tag+">","");
}
        
export const renderTitle = () => {
    const content = store.uiStore.getContent(CONTENT_TYPE_WARNING);
    if(!content) return ("");
    
    let key = "cw_title_"; 
    key += (window._LANG_ === _LANG_EN_) ? "en_int" : "cy_int";
    return (content[key] && content[key].comment && (<h2>{parse(removeHtmlTag(content[key].comment))}</h2>)) || <h2><FormattedMessage id="modal.contentwarning.title"/></h2>;
}

const renderLinkIntl = (props) => {
    const linkTerms = props.intl.formatMessage({id: "modal.contentwarning.terms.link" });
    const content = store.uiStore.getContent(CONTENT_TYPE_WARNING);
    if(!content) return ("");
    
    let key = "cw_link_"; 
    key += (window._LANG_ === _LANG_EN_) ? "en_int" : "cy_int";
    
    if(content[key] && content[key].comment) {
        return (<a className="Link-f" href={linkTerms} target="_blank">{parse(removeHtmlTag(content[key].comment))}</a>);
    }
    return (
        <FormattedMessage 
            id={"modal.contentwarning.terms.text"} 
            values={{
                a: msg => <a className="Link-f" href={linkTerms} target="_blank">{msg}</a>
            }}
        />
    );
}

export const renderLink = injectIntl(renderLinkIntl);

export const renderText = () => {
    const content = store.uiStore.getContent(CONTENT_TYPE_WARNING);
    if(!content) return ("");
    
    let key = "cw_txt_"; 
    key += (window._LANG_ === _LANG_EN_) ? "en_int" : "cy_int";
    
    return (content[key] && content[key].comment && parse(content[key].comment)) || (<p><FormattedMessage id="modal.contentwarning.description"/></p>);
}

export const renderCheck = () => {
    const content = store.uiStore.getContent(CONTENT_TYPE_WARNING);
    if(!content) return ("");
    
    let key = "cw_check_"; 
    key += (window._LANG_ === _LANG_EN_) ? "en_int" : "cy_int";
    
    return (content[key] && content[key].comment && parse(content[key].comment)) || (<p><FormattedMessage id="modal.contentwarning.terms.checkbox"/></p>);
}

const ContentWarningModal = (props) => {
    
    const onAgree = () => {
        if(props.config.disabled) {
            props.config.fail(true);
        } else {
            const { cookies } = props;
            const date = new Date();
            date.setDate(date.getDate() + 365 * 3);
            const string = date.toUTCString();
            console.log(string);
            //cookies.set('__ac', { agreed: true }, { path: '/', maxAge: 60*60*24*365*10 /* 3 years */ });
            cookies.set('__ac', { agreed: true }, { path: '/', expires: date /* 3 years */ });
            props.config.fail(false);
            props.onHide();
        }
    }
    
    if(props.loading) {
        return (
            <Modal
                className="ContentWarning"
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <p className="loading">Loading ...</p>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <Modal
            className="ContentWarning"
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    { props.title }
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                { props.text }
                <p>{ props.link }</p>
            </Modal.Body>
            
            <Modal.Footer>
                <div className="ContentWarningControls">
                    <div className={"Row ContentWarningCheck"}>
                        <Form.Check.Label>
                            <Form.Check
                                aria-label={"Agree Terms of use"}
                                className={"Check-f"}
                                type="checkbox"
                                onChange={props.config.enable}/>
                            {props.check}
                        </Form.Check.Label>
                        <Button
                            aria-label="agree-btn"
                            variant={"dark"}
                            className={"Btn-f"}
                            onClick={onAgree}>
                            <FormattedMessage id="common.continue"/>
                        </Button>
                    </div>
                    {
                        props.config.failed ? <div className="warn"><FormattedMessage id="modal.contentwarning.terms.warning"/></div> : ""
                    }
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default injectIntl(withCookies(ContentWarningModal));
