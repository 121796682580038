import { _LANG_EN_, _LANG_CY_, _DOMAIN_EN_, _DOMAIN_CY_ } from "Config/constants";

export const createURL = (props/*filter, fulltext = "", within = ""*/) => {
    const params = new URLSearchParams();
    if (props.filter) {
        const keys = Object.keys(props.filter);

        keys.forEach(key => {
            let current = props.filter[key].getValues();
            if (current.length) {
                current.forEach(value => {
                    params.append(key, value);
                });
            }
        });
    }

    //props.fulltext && params.append('fulltext', props.fulltext);
    //props.within && params.append('within', props.within);
	props.terms && props.terms.length && props.terms.forEach(term => { params.append('terms', term); });
    props.page && params.append('page', props.page);
    props.sorting && params.append('sorting', props.sorting);
    props.results && params.append('results', props.results);
    props.viewmode && params.append('viewmode', props.viewmode);
    props.user && params.append('pl', props.index);

    return params.toString();
}

export const setURL = (location) => {
    const linkDesktop = document.getElementById('llgc_mobile_lang_switch');
    const linkMobile = document.getElementById('llgc_lang_switch');

    let href = getDomain() + location.pathname + location.search
    linkDesktop.href = href;
    linkMobile.href = href;
}

const getDomain = () => {
    //ACCESSIBILITY
    let htmlEl = document.querySelector("html");
    htmlEl.setAttribute("lang", window._LANG_);
    //ACCESSIBILITY END

    switch (window._LANG_) {
        case _LANG_EN_:
            if (process.env.REACT_APP_SERVER === "PROD") {
                return window.location.protocol + "//" + getSubDomain() + _DOMAIN_CY_;
            }
            if (process.env.REACT_APP_SERVER === "DEV") {
                return window.location.protocol + "//" + _DOMAIN_CY_ + ":3005";
            }
            if (process.env.REACT_APP_SERVER === "DW") {
                return window.location.protocol + "//" + _DOMAIN_CY_ + ".dw-soft.de";
            }
            return window.location.protocol + "//" + getSubDomain() + _DOMAIN_CY_;
        //return window.location.protocol + "//cliptest.llyfrgell.cymru";
        case _LANG_CY_:
            //ACCESSIBILITY BEGIN
            htmlEl.setAttribute("lang", _LANG_CY_);
            //ACCESSIBILITY END
            if (process.env.REACT_APP_SERVER === "PROD") {
                return window.location.protocol + "//" + getSubDomain() + _DOMAIN_EN_;
            }
            if (process.env.REACT_APP_SERVER === "DEV") {
                return window.location.protocol + "//" + _DOMAIN_EN_ + ":3005";
            }
            if (process.env.REACT_APP_SERVER === "DW") {
                return window.location.protocol + "//" + _DOMAIN_EN_ + ".dw-soft.de";
            }
            return window.location.protocol + "//" + getSubDomain() + _DOMAIN_EN_;
        //return window.location.protocol + "//cliptest.library.wales";
        default:
            return "";
    }
}

const getSubDomain = (location) => {
    const host = window.location.hostname;
    const domain = window._LANG_ === _LANG_EN_ ? _DOMAIN_EN_ : _DOMAIN_CY_;
    const index = host.indexOf(domain);

    // check -1 and 0 >> if 0 = empty subdomain no need to give it back
    if (index > 0) {
        return host.substring(0, index);
    }
    return "";
}
